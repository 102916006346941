import React from "react";
import { Link } from "gatsby";
import Head from "../components/head";
import errorStyles from "./404.module.css";

const ErrorPage = props => {
  return (
    <>
      <Head title="Page not found"></Head>
      <div className={errorStyles.errorContainer}>
        <div>
          <h1>404</h1>
          <h2>Page not found</h2>
          <p>
            The page you are looking for does not exist or and error occured.{" "}
            <Link to="/">Home Page</Link>.
          </p>
        </div>
      </div>
    </>
  );
};
export default ErrorPage;
